import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VColorPicker } from 'vuetify/lib/components/VColorPicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('label',{staticClass:"label",staticStyle:{"font-weight":"bold","font-size":"20px","display":"block","margin-bottom":"15px"}},[_vm._v("Sidebar settings")]),_c('div',{staticStyle:{"display":"flex","align-items":"center","margin-bottom":"15px"}},[_c('label',{staticClass:"label",staticStyle:{"margin-bottom":"0","margin-right":"15px","min-width":"250px"}},[_vm._v(" Background color ")]),_c('div',{staticClass:"control"},[_c(VMenu,{attrs:{"top":"","nudge-left":"16","nudge-bottom":"98","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"custom__menu--color",style:(("background-color:" + (_vm.landing.branding.sidebar_menu.background)))},on))]}}]),model:{value:(_vm.show_sidebar_background_color),callback:function ($$v) {_vm.show_sidebar_background_color=$$v},expression:"show_sidebar_background_color"}},[_c(VCard,[_c(VCardText,{staticClass:"pa-0"},[_c(VColorPicker,{attrs:{"flat":""},model:{value:(_vm.landing.branding.sidebar_menu.background),callback:function ($$v) {_vm.$set(_vm.landing.branding.sidebar_menu, "background", $$v)},expression:"landing.branding.sidebar_menu.background"}})],1)],1)],1)],1)]),_c('div',{staticStyle:{"display":"flex","align-items":"center","margin-bottom":"15px"}},[_c('label',{staticClass:"label",staticStyle:{"margin-bottom":"0","margin-right":"15px","min-width":"250px"}},[_vm._v(" Border color ")]),_c('div',{staticClass:"control"},[_c(VMenu,{attrs:{"top":"","nudge-left":"16","nudge-bottom":"98","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"custom__menu--color",style:(("background-color:" + (_vm.landing.branding.sidebar_menu.border_color)))},on))]}}]),model:{value:(_vm.show_sidebar_border_color),callback:function ($$v) {_vm.show_sidebar_border_color=$$v},expression:"show_sidebar_border_color"}},[_c(VCard,[_c(VCardText,{staticClass:"pa-0"},[_c(VColorPicker,{attrs:{"flat":""},model:{value:(_vm.landing.branding.sidebar_menu.border_color),callback:function ($$v) {_vm.$set(_vm.landing.branding.sidebar_menu, "border_color", $$v)},expression:"landing.branding.sidebar_menu.border_color"}})],1)],1)],1)],1)]),_c('div',{staticStyle:{"display":"flex","align-items":"center","margin-bottom":"15px"}},[_c('label',{staticClass:"label",staticStyle:{"margin-bottom":"0","margin-right":"15px","min-width":"250px"}},[_vm._v("Show icons")]),_c(VSwitch,{attrs:{"color":"#6ab64a","hide-details":""},model:{value:(_vm.landing.branding.sidebar_menu.show_icons),callback:function ($$v) {_vm.$set(_vm.landing.branding.sidebar_menu, "show_icons", $$v)},expression:"landing.branding.sidebar_menu.show_icons"}})],1),_c('div',{staticStyle:{"display":"flex","align-items":"center","margin-bottom":"15px"}},[_c('label',{staticClass:"label",staticStyle:{"margin-bottom":"0","margin-right":"15px","min-width":"250px"}},[_vm._v(" Icon color ")]),_c('div',{staticClass:"control"},[_c(VMenu,{attrs:{"top":"","nudge-left":"16","nudge-bottom":"98","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"custom__menu--color",style:(("background-color:" + (_vm.landing.branding.sidebar_menu.icon_colors)))},on))]}}]),model:{value:(_vm.show_sidebar_icon_color),callback:function ($$v) {_vm.show_sidebar_icon_color=$$v},expression:"show_sidebar_icon_color"}},[_c(VCard,[_c(VCardText,{staticClass:"pa-0"},[_c(VColorPicker,{attrs:{"flat":""},model:{value:(_vm.landing.branding.sidebar_menu.icon_colors),callback:function ($$v) {_vm.$set(_vm.landing.branding.sidebar_menu, "icon_colors", $$v)},expression:"landing.branding.sidebar_menu.icon_colors"}})],1)],1)],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }