<template>
<div>
  <label class="label" style="font-weight:bold;font-size:20px;display:block;margin-bottom:15px;">Sidebar settings</label>
  <div style="display: flex;align-items: center;margin-bottom: 15px;">
    <label
        class="label"
        style="margin-bottom: 0;margin-right: 15px;min-width:250px;"
    >
      Background color
    </label>
    <div class="control">
      <v-menu
          top
          nudge-left="16"
          nudge-bottom="98"
          :close-on-content-click="false"
          v-model="show_sidebar_background_color"
      >
        <template v-slot:activator="{ on }">
          <div
              class="custom__menu--color"
              :style="`background-color:${landing.branding.sidebar_menu.background}`"
              v-on="on"
          />
        </template>
        <v-card>
          <v-card-text class="pa-0">
            <v-color-picker v-model="landing.branding.sidebar_menu.background" flat />
          </v-card-text>
        </v-card>
      </v-menu>
    </div>
  </div>
  <div style="display: flex;align-items: center;margin-bottom: 15px;">
    <label
        class="label"
        style="margin-bottom: 0;margin-right: 15px;min-width:250px;"
    >
      Border color
    </label>
    <div class="control">
      <v-menu
          top
          nudge-left="16"
          nudge-bottom="98"
          :close-on-content-click="false"
          v-model="show_sidebar_border_color"
      >
        <template v-slot:activator="{ on }">
          <div
              class="custom__menu--color"
              :style="`background-color:${landing.branding.sidebar_menu.border_color}`"
              v-on="on"
          />
        </template>
        <v-card>
          <v-card-text class="pa-0">
            <v-color-picker v-model="landing.branding.sidebar_menu.border_color" flat />
          </v-card-text>
        </v-card>
      </v-menu>
    </div>
  </div>
  <div style="display:flex;align-items:center;margin-bottom: 15px;">
    <label class="label"
           style="margin-bottom: 0;margin-right: 15px;min-width:250px;">Show icons</label>
    <v-switch color="#6ab64a"
              hide-details
              v-model="landing.branding.sidebar_menu.show_icons"
    ></v-switch>
  </div>
  <div style="display: flex;align-items: center;margin-bottom: 15px;">
    <label
        class="label"
        style="margin-bottom: 0;margin-right: 15px;min-width:250px;"
    >
      Icon color
    </label>
    <div class="control">
      <v-menu
          top
          nudge-left="16"
          nudge-bottom="98"
          :close-on-content-click="false"
          v-model="show_sidebar_icon_color"
      >
        <template v-slot:activator="{ on }">
          <div
              class="custom__menu--color"
              :style="`background-color:${landing.branding.sidebar_menu.icon_colors}`"
              v-on="on"
          />
        </template>
        <v-card>
          <v-card-text class="pa-0">
            <v-color-picker v-model="landing.branding.sidebar_menu.icon_colors" flat />
          </v-card-text>
        </v-card>
      </v-menu>
    </div>
  </div>
</div>
</template>

<script>
import {mapState} from "vuex"
export default {
  name: "SidebarBranding",
  data(){
    return{
      show_sidebar_icon_color:false,
      show_sidebar_background_color:false,
      show_sidebar_border_color:false
    }
  },
  computed:{
    ...mapState(['landing'])
  }
}
</script>

<style scoped>

</style>
